.hod-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .hod-form-container {
    width: 300px;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
    color: #333;
  }
  
  input[type="text"],
  input[type="password"],
  textarea,
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .btn-submit {
    background-color: #007acc;
    color: #fff;
    padding: 10px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-submit:hover {
    background-color: #005b9f;
  }
  
  .hod-submitted {
    text-align: center;
    margin-top: 20px;
    color: green;
  }
  .hod-submitted p {
    margin: 5px 0;
  }