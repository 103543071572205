.App{
    margin: 0% 10%;
}
Accordion{
    background-color: grey;
}

@media (max-width:700px) {
    .App{
        margin: 0px;
        padding: 0px;
    }
}