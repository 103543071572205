/* Upload.css */
.title{
    font-weight: bold;
    text-align: center;
    padding-top: 1cm;
    font-size: 24px;
  }
  .form-container {
    max-width: 400px;
    margin: 20px auto;
    padding: 40px;
    border: 5px solid #ccc; 
    border-radius: 20px; 
    background-color: #f9f9f9; 
    border: 1px solid #ccc; 
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .form-container:hover {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.9);
  }
  
  .input-container {
    margin-bottom: 15px;  
  }
  
  .input-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .input-container input[type="text"],
  .input-container textarea {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .input-container input[type="file"] {
    margin-top: 5px;
  }
  
  .button-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .button-container button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  