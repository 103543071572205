.admin-login-main{
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    min-height: 100vh;
    justify-content: center;
    text-align: center;
    align-items: center;
    /* background-color: rgba(75, 75, 143, 0.637); */
}

.admin-login-form{
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* width: 90vw; */
    background-color: rgb(121, 118, 118);
    /* align-items: center;
    justify-content: center; */
    padding: 10PX;
    /* border-radius: 5%; */
    justify-content: center;
    align-items: center;
    height: fit-content;
}


.login-form {
    min-width: max-content;
    padding: 30px;
    background-color: #f5f5f5;
    border-radius: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.login-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    gap: 5px;
    align-items: center;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.btn-admin-login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.btn-admin-login:hover {
    background-color: #0056b3;  
}

.forgot-password {
    display: block;
    text-align: center;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
}

.forgot-password:hover {
    text-decoration: underline;
}

.library-image {
    display: block;
    
}



@media (max-width:700px) {
    *{
        margin: 0px;
        padding: 0px;
    }
    .library-image{
        max-width: 370px;
        max-height: 200px;
    }.login-form{
        min-width: 370px;
    }
    .admin-login-form  {
        display: flex;
        flex-direction: column-reverse;
        min-width: 410px;
        border-radius: 15px;

    }
}