/* .search{
    padding-left: 27%;
    width: fit-content;
}
.eventsadd{
    margin: 10px;
} */
.bio{
    width: 10000px;
}
.main_div{
    display: flex;
    flex-direction: column;
    align-items: auto;
        width: 100%;
        background-color: aliceblue;
        text-align: center;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        flex-wrap: wrap;
        padding: 20px;
        margin: 6px;
        text-align: center;
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 15px;
        /* margin-right: 200px;
        margin-left: 250px; */
        background-color: aliceblue;
}