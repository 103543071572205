.admin-main{
    display:flex;
    flex-direction: column;
    justify-content: start;
    padding: 10px;
    /* align-items: center; */
    /* min-height: 20vh; */
    min-width: 70vw;
    border-radius: 10px;
    background-color: rgb(132, 159, 171);
}
.all-requests, .updates-requests, .dmc-requests, .news-requests, .dept-requests{
    min-height: 15vh;
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* margin: 2%; */
    /* padding: 2%; */
    /* background-color: rgb(211, 211, 211); */

}
.admin-main a button{
    margin: 1%;
    background-color: cadetblue;
    color: rgb(155, 8, 52);

}