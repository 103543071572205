.complete-gallery-container {
  text-align: center;
  padding: 10px;
}

.complete-gallery-container h1 {
  margin-bottom: 20px;
  font-size: xx-large;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0; /* Remove default margin */
  padding: 20px;
  margin-bottom: 20px;
  justify-content: center;
}

.grid-image {
  width: 330px;
  height: 250px;
  transition: transform 0.3s ease;
  padding-right: 20px;
  padding-bottom: 15px;
}

/* Add hover effect */
.grid-image:hover {
  transform: scale(1.1);
}

.enlarged-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.818); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.enlarged-image img {
  width: 600px;
  height: 400px;
  border: 5px solid #000000;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}

.enlarged-image p {
  color: #fff;
  background-color: #524f4fa7;
  font-size: 18px;
  margin-top: 20px;
  width: 600px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  border-radius: 15px;
  padding: 15px 20px; /* Add padding to the left and right sides */
}
.enlarged-image button{
  background-color:rgba(226, 255, 108, 0.752);
  margin: 1%;
  border-radius: 25px;
  font-family:'Times New Roman', Times, serif;
  font-size: large;
  height: 40px;
  width:  100px;  
}
.back-to-home{
  display: flex;
  color: #000000;
  text-decoration: none;
  align-items: center;
}